// @flow
import { connect } from 'react-redux'

import {
  setCurrentOfficeId,
  setCurrentRegionId,
} from '@src/redux/modules/user/preferences'
import { getCurrentRegion } from '@src/redux/selectors'

const select = state => ({
  isUserLoggedIn: state.user.isLoggedIn,
  currentOfficeId: state.user.preferences.currentOfficeId,
  currency: getCurrentRegion(state) && getCurrentRegion(state).currency,
})

const actions = {
  setCurrentOfficeId,
  setCurrentRegionId,
}

export default connect(
  select,
  actions
)
