// @flow
import { connect } from 'react-redux'

import { getCurrentRegion } from '@src/redux/selectors'

const select = state => ({
  currentOfficeId: state.user.preferences.currentOfficeId,
  currency: getCurrentRegion(state).currency,
})

export default connect(select)
