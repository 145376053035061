// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

import PaymentMethodsScreen from '@src/components/my-company/PaymentMethods/PaymentMethodsScreen'

const CreditCardsQuery = gql`
  query CreditCards($officeId: ID!) {
    credit_cards(office_id: $officeId) {
      ...PaymentMethodsScreenCreditCard
    }
  }
  ${PaymentMethodsScreen.fragments.creditCard}
`

const withCreateCreditCard = graphql(
  gql`
    mutation CreateCreditCard(
      $officeId: ID!
      $conektaToken: String
      $stripeToken: String
    ) {
      create_credit_card(
        office_id: $officeId
        conekta_token: $conektaToken
        stripe_token: $stripeToken
      ) {
        ...PaymentMethodsScreenCreditCard
      }
    }
    ${PaymentMethodsScreen.fragments.creditCard}
  `,
  {
    name: 'createCreditCardMutation',
    props: ({ createCreditCardMutation }) => ({
      createCreditCard: (
        officeId: string,
        conektaToken?: string,
        stripeToken?: string
      ) =>
        createCreditCardMutation({
          variables: { officeId, conektaToken, stripeToken },
          update: (proxy, { data: { create_credit_card: creditCard } }) => {
            const data = proxy.readQuery({
              query: CreditCardsQuery,
              variables: { officeId },
            })

            data.credit_cards.push(creditCard)

            proxy.writeQuery({
              query: CreditCardsQuery,
              variables: { officeId },
              data,
            })
          },
        }),
    }),
  }
)

export default compose(withCreateCreditCard)
