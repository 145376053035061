// @flow
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export default graphql(
  gql`
    query InitialData {
      current_user {
        id
        email
        first_name
        last_name
        tawk_validation_hash
        permissions
        region {
          id
          slug
          name
          time_zone
          currency
        }
        companies {
          id
          name
        }
        offices {
          id
          name
          company {
            id
          }
          region {
            id
            slug
            name
            time_zone
            currency
          }
          balance
          monthly_budget
          pay_to_confirm_order
        }
      }
    }
  `,
  {
    skip: props => !props.userIsLoggedIn,
  }
)
