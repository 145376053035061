// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'

import SummaryScreen from './SummaryScreen'

import redux from './redux'

import type { CurrentUser } from '@src/higherOrderComponents/withCurrentUser/graphql'

type SummaryContainerProps = {
  currency: string,
  currentUser: CurrentUser,
  currentOfficeId: string,
}

export class SummaryContainer extends Component<SummaryContainerProps, *> {
  render() {
    const { currency, currentOfficeId, ...rest } = this.props

    return currentOfficeId ? (
      <SummaryScreen
        currentOfficeId={currentOfficeId}
        currency={currency}
        {...rest}
      />
    ) : null
  }
}

export default compose(redux)(SummaryContainer)
