// @flow
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'

const withInviteUser = graphql(
  gql`
    mutation inviteUserToOffice($officeId: ID!, $email: String!) {
      invite_user(office_id: $officeId, email: $email) {
        ... on UserInvitation {
          id
        }

        ... on User {
          id
        }
      }
    }
  `,
  {
    name: 'inviteUser',
    props: ({ inviteUser }) => ({
      inviteUser: (officeId: string, email: string) =>
        inviteUser({
          variables: { officeId, email },
        }),
    }),
  }
)

export default compose(withInviteUser)
