// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm, SubmissionError } from 'redux-form'
import { injectIntl } from 'react-intl'

import OfficeInformationModal from './OfficeInformationModal'
import graphql from './graphql'
import redux from './redux'
import convertGeocoderResultToAddress from '@src/utils/convertGeocoderResultToAddress'
import * as errorReporter from '@src/lib/errorReporter'
import * as events from '@src/lib/events'

import type { FormProps } from 'redux-form'
import type { IntlShape } from 'react-intl'

import type {
  AddressInput,
  CompanyInput,
  OfficeInput,
  Company,
  Office,
} from './graphql'

type OfficeInformationModalContainerProps = {
  ...FormProps,
  ...IntlShape,
  initialValues?: Object,
  closeModal: () => void,
  companyId?: string,
  createCompany: (
    company: CompanyInput
  ) => Promise<{
    data: {
      create_company: Company,
    },
  }>,
  createOffice: (
    companyId: string,
    office: OfficeInput
  ) => Promise<{
    data: {
      create_office: Office,
    },
  }>,
  setCurrentCompanyId: (companyId: string) => void,
  setCurrentOfficeId: (officeId: string) => void,
}

type FormData = {
  companyName?: string,
  name: string,
  address: Object, // TODO: Create type for GeoSearchControl suggests
  phone: string,
  squareMeters?: string,
  occupancy?: string,
  maxOccupancy?: string,
}

export class OfficeInformationModalContainer extends Component<
  OfficeInformationModalContainerProps,
  *
> {
  handleSubmit: Function
  formData: FormData

  constructor(props: OfficeInformationModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit(formData: FormData) {
    this.formData = formData
    const { companyId, closeModal } = this.props

    try {
      if (companyId) {
        await this.createOffice(companyId)
      } else {
        await this.createCompanyAndOffice()
      }
    } catch (e) {
      errorReporter.log(e)
      throw new SubmissionError({ _error: e.message })
    }

    closeModal()
    this.reloadWindow()
  }

  reloadWindow() {
    window.location.reload()
  }

  async createCompanyAndOffice(): Promise<Office> {
    const company = await this.createCompany()
    return this.createOffice(company.id)
  }

  async createCompany(): Promise<Company> {
    const { companyName } = this.formData

    const result = await this.props.createCompany({
      name: companyName,
    })

    const company = result.data.create_company

    this.props.setCurrentCompanyId(company.id)

    return company
  }

  async createOffice(companyId: string): Promise<Office> {
    const officeInput: OfficeInput = {
      name: this.formData.name,
      address: this.getAddress(),
      phone: this.formData.phone,
      square_meters:
        typeof this.formData.squareMeters === 'string'
          ? Number.parseInt(this.formData.squareMeters, 10)
          : this.formData.squareMeters,
      occupancy:
        typeof this.formData.occupancy === 'string'
          ? Number.parseInt(this.formData.occupancy, 10)
          : this.formData.occupancy,
      max_occupancy:
        typeof this.formData.maxOccupancy === 'string'
          ? Number.parseInt(this.formData.maxOccupancy, 10)
          : this.formData.maxOccupancy,
    }

    const result = await this.props.createOffice(companyId, officeInput)
    const office = result.data.create_office

    this.logOfficeCreated()

    this.props.setCurrentOfficeId(office.id)

    return office
  }

  logOfficeCreated() {
    events.initialOfficeCreated()
  }

  getAddress(): AddressInput {
    const address = convertGeocoderResultToAddress(this.formData.address.gmaps)

    return {
      street: address.street,
      number: address.number,
      state: address.state,
      city: address.city,
      postal_code: address.postal_code,
      latitude: address.latitude,
      longitude: address.longitude,
    }
  }

  render() {
    const { handleSubmit, submitting, error, companyId, ...rest } = this.props

    return (
      <OfficeInformationModal
        handleSubmit={handleSubmit(this.handleSubmit)}
        submitting={submitting}
        error={error}
        regionSlug={this.props.regionSlug}
        companyId={companyId}
        {...rest}
      />
    )
  }
}

export default compose(
  redux,
  graphql,
  reduxForm({
    form: 'requestOfficeInformation',
  }),
  injectIntl
)(OfficeInformationModalContainer)
