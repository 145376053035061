import React from 'react'
import { injectIntl } from 'react-intl'

import Select from '@src/components/ui/Select'
import messages from './messages'

import type { IntlShape } from 'react-intl'

type MasterListPeriodicitySelectProps = {
  intl: IntlShape,
  onPeriodicityChange: () => void,
}

export function MasterListPeriodicitySelect(
  props: MasterListPeriodicitySelectProps
) {
  const {
    intl: { formatMessage },
  } = props

  return (
    <Select
      name="periodicity"
      label={formatMessage(messages.periodicityLabel)}
      onChange={e => props.onChange(e.currentTarget.value)}
    >
      <option value="IRREGULAR">
        {formatMessage(messages.periodicityIRREGULAR)}
      </option>
      <option value="WEEKLY">
        {formatMessage(messages.periodicityWEEKLY)}
      </option>
      <option value="BIWEEKLY">
        {formatMessage(messages.periodicityBIWEEKLY)}
      </option>
      <option value="MONTHLY">
        {formatMessage(messages.periodicityMONTHLY)}
      </option>
    </Select>
  )
}

export default injectIntl(MasterListPeriodicitySelect)
