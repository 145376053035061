// @flow
import React, { Component } from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import BalanceWidget from './BalanceWidget'
import NextEventsWidget from './NextEventsWidget'

import styles from './SummaryScreen.module.scss'

import type { CurrentUser } from '@src/higherOrderComponents/withCurrentUser/graphql'

type SummaryScreenProps = {
  currentUser: CurrentUser,
  currentOfficeId: string,
  currency: string,
}

class SummaryScreen extends Component<SummaryScreenProps> {
  render() {
    const titleStyle = cx('text-title-1', styles.title)

    return (
      <div>
        <h1 className={titleStyle}>
          <FormattedMessage
            id="SummaryScreen.userGreeting"
            defaultMessage="Hola"
            description="User greeting in the summary screen"
          />{' '}
          {this.props.currentUser && this.props.currentUser.first_name}
        </h1>

        <h2 className={cx('text-headline-2', styles.summarySubtitle)}>
          <FormattedMessage
            id="SummaryScreen.summaryTitle"
            defaultMessage="Mi resumen"
            description="Catalog section title"
          />
        </h2>

        {this.renderSummary()}
      </div>
    )
  }

  renderSummary() {
    const { currentOfficeId, currency } = this.props

    const summaryContainerStyle = cx('row', styles.summaryContainer)
    const summaryRowStyle = cx('col-12', styles.summaryRow)

    return (
      <div className={summaryContainerStyle}>
        <div className={summaryRowStyle}>
          <div className="row">
            <BalanceWidget
              currency={currency}
              currentOfficeId={currentOfficeId}
            />
            <NextEventsWidget currentOfficeId={currentOfficeId} />
          </div>
        </div>
      </div>
    )
  }
}

export default SummaryScreen
