// @flow
import React from 'react'
import cx from 'classnames'
import compose from 'lodash/flowRight'
import { reduxForm } from 'redux-form'
import { injectIntl, FormattedMessage } from 'react-intl'
import gql from 'graphql-tag'

import styles from './UpdateMasterListModal.module.scss'
import messages from './messages'
import Button from '@src/components/ui/Button'
import Input from '@src/components/ui/Input'
import FormValidationError from '@src/components/ui/FormValidationError'
import MasterListPeriodicitySelect from '@src/components/ui/MasterListPeriodicitySelect'
import { isRequired } from '@src/utils/validation'
import {
  SingleDatePickerFormGroup as SingleDatePicker,
  FocusHelper as SingleDatePickerFocusHelper,
} from '@src/components/ui/SingleDatePicker'

import type { MessageDescriptor } from 'react-intl'

type UpdateMasterListModalProps = {
  handleSubmit: () => void,
  submitting: boolean,
  error?: any,
  initialValues: {
    periodicity: string,
    startingDate: string,
  },
  isShippingDate: () => void,
  onPeriodicityChange: () => void,
  onChangeStartingDeliveryDate: () => void,
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export function UpdateMasterListModal(props: UpdateMasterListModalProps) {
  const {
    handleSubmit,
    submitting,
    error,
    isShippingDate,
    intl: { formatMessage },
  } = props

  const displayAutomatedOrderWarning =
    props.initialValues.periodicity !== 'IRREGULAR'

  return (
    <div className={cx(styles.container)}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          <FormattedMessage
            id="UpdateMasterListModal.title"
            defaultMessage="Editar lista"
            description="Update master list modal title"
          />
        </h1>
      </div>

      <form className={styles.body}>
        <Input
          name="name"
          label={formatMessage(messages.nameLabel)}
          validate={[isRequired]}
          maxLength="72"
        />

        <MasterListPeriodicitySelect onChange={props.onPeriodicityChange} />

        {displayAutomatedOrderWarning && (
          <SingleDatePickerFocusHelper>
            {(focused, handleFocusChange) => (
              <SingleDatePicker
                name="startingDate"
                label="A partir de"
                value={props.initialValues.startingDate}
                onChange={props.onChangeStartingDeliveryDate}
                focused={focused}
                onFocusChange={handleFocusChange}
                meta={{}}
                isOutsideRange={isShippingDate}
              />
            )}
          </SingleDatePickerFocusHelper>
        )}

        {displayAutomatedOrderWarning && (
          <p>
            Recibirás un recordatorio unos días antes y podrás modificar la
            lista o detener la entrega.
          </p>
        )}

        {error && <FormValidationError error={error} />}

        <div className={cx(styles.section, styles.actionButtons)}>
          <Button
            className={styles.submitButton}
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <FormattedMessage
                id="UpdateMasterListModal.submitButtonWhileSubmitting"
                defaultMessage="Actualizando la lista..."
                description="Submit button text while updating the master list"
              />
            ) : (
              <FormattedMessage
                id="UpdateMasterListModal.submitButton"
                defaultMessage="Actualizar la lista"
                description="Submit button text to update a master list"
              />
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}

UpdateMasterListModal.fragments = {
  masterList: gql`
    fragment UpdateMasterListModalMasterList on MasterList {
      id
      name
      periodicity
      starting_date
    }
  `,
}

export default compose(
  reduxForm({
    form: 'updateMasterList',
  }),
  injectIntl
)(UpdateMasterListModal)
