// @flow
import Storage from 'local-storage-fallback'

import {
  createActionType as createActionTypeCreator,
  action as createAction,
  reducer as createReducer,
} from '@src/redux/utils'
import * as storageKeys from '@src/constants/storageKeys'

const createActionType = createActionTypeCreator('User Preferences')

export const SET_CURRENT_COMPANY_ID = createActionType('SET_CURRENT_COMPANY_ID')
export const SET_CURRENT_OFFICE_ID = createActionType('SET_CURRENT_OFFICE_ID')
export const SET_CURRENT_REGION_ID = createActionType('SET_CURRENT_REGION_ID')

export const setCurrentCompanyId = (companyId: string) => {
  Storage.setItem(storageKeys.CURRENT_COMPANY_ID, companyId)
  return createAction(SET_CURRENT_COMPANY_ID, companyId)
}

export const setCurrentOfficeId = (officeId: string) => {
  Storage.setItem(storageKeys.CURRENT_OFFICE_ID, officeId)
  return createAction(SET_CURRENT_OFFICE_ID, officeId)
}

export const setCurrentRegionId = (regionId: string) => {
  Storage.setItem(storageKeys.CURRENT_REGION_ID, regionId)
  return createAction(SET_CURRENT_REGION_ID, regionId)
}

export const initialState = {
  currentCompanyId: Storage.getItem(storageKeys.CURRENT_COMPANY_ID) || null,
  currentOfficeId: Storage.getItem(storageKeys.CURRENT_OFFICE_ID) || null,
  currentRegionId: Storage.getItem(storageKeys.CURRENT_REGION_ID) || null,
}

const reducer = createReducer(
  {
    [SET_CURRENT_COMPANY_ID]: (state, action) => ({
      ...state,
      currentCompanyId: action.payload,
    }),

    [SET_CURRENT_OFFICE_ID]: (state, action) => ({
      ...state,
      currentOfficeId: action.payload,
    }),

    [SET_CURRENT_REGION_ID]: (state, action) => ({
      ...state,
      currentRegionId: action.payload,
    }),
  },
  initialState
)

export default reducer
