// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'

import DashboardScreen from './DashboardScreen'
import redux from './redux'

type DashboardContainerProps = {
  initialData: {
    loading: boolean,
    currentUser?: {
      region: { slug: string },
      companies: Array<{ id: string }>,
      offices: Array<{ id: string }>,
    },
  },
  openModal: ({ type: string, data?: Object }) => void,
}

export class DashboardContainer extends Component<DashboardContainerProps, *> {
  constructor(props: DashboardContainerProps) {
    super(props)

    this.state = {
      shouldOpenOfficeInformationModal: true,
    }
  }

  componentDidMount() {
    this.openOfficeInformationModal()
  }

  openOfficeInformationModal() {
    const { loading, currentUser } = this.props.initialData

    if (!this.state.shouldOpenOfficeInformationModal) {
      return
    }

    if (loading || !currentUser) {
      return
    }

    const company = currentUser.companies[0]
    const needsOfficeInformation = currentUser.offices.length === 0

    if (needsOfficeInformation) {
      const regionSlug = currentUser.region.slug

      this.props.openModal({
        type: 'OfficeInformationModal',
        companyId: company && company.id,
        regionSlug: regionSlug,
      })
    }

    this.setState(() => ({
      shouldOpenOfficeInformationModal: false,
    }))
  }

  render() {
    const { currentUser } = this.props.initialData

    if (!currentUser) {
      return (
        <div>
          Error: no se pudo encontrar el usuario consulta a tu asesor de cuenta.
        </div>
      )
    }

    const company = currentUser.companies[0]
    if (!company) {
      return (
        <div>
          Error: este usuario no tiene configurada una compañía, consulta a tu
          asesor de cuenta.
        </div>
      )
    }

    return <DashboardScreen currentUser={currentUser} />
  }
}

export default compose(redux)(DashboardContainer)
