// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { reduxForm, SubmissionError } from 'redux-form'

import InviteUserToOfficeModal from './InviteUserToOfficeModal'
import graphql from './graphql'
import { isEmail } from '@src/utils/validation'
import * as errorReporter from '@src/lib/errorReporter'

type FormData = {
  emails: Array<string>,
}

type InviteUserToOfficeModalContainerProps = {
  office: Object,
  closeModal: () => void,
  inviteUser: (officeId: string, email: string) => Promise<void>,

  // Redux form props
  handleSubmit: Function,
  submitting: boolean,
  error?: string,
  reset: Function,
}

export class InviteUserToOfficeModalContainer extends Component<
  InviteUserToOfficeModalContainerProps,
  *
> {
  handleSubmit: (formData: FormData) => Promise<void>

  constructor(props: InviteUserToOfficeModalContainerProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = { error: '' }
  }

  async handleSubmit(formData: FormData) {
    const validEmails = formData.emails.filter(email => {
      const hasError = isEmail(email)
      return !hasError
    })

    try {
      const inviteUserPromises = validEmails.map(email =>
        this.inviteUser(email)
      )
      await Promise.all(inviteUserPromises)
    } catch (e) {
      this.setState(() => ({ error: e.graphQLErrors[0].message }))

      errorReporter.log(e)
      throw new SubmissionError({
        _error: 'Hubo un error. Por favor vuelve a intentar.',
      })
    }

    this.props.closeModal()
  }

  inviteUser(email: string) {
    return this.props.inviteUser(this.props.office.id, email)
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const { error } = this.state

    return (
      <InviteUserToOfficeModal
        onSubmit={handleSubmit(this.handleSubmit)}
        submitting={submitting}
        error={error}
      />
    )
  }
}

export default compose(
  graphql,
  reduxForm({
    form: 'InviteUserToOfficeModal',
  })
)(InviteUserToOfficeModalContainer)
