// @flow
import React, { Component } from 'react'
import compose from 'lodash/flowRight'
import { camelCase, mapKeys } from 'lodash'
import { withRouter } from 'react-router-dom'

import { IntlProvider } from '@src/components/react-intl'
import graphql from './graphql'
import redux from './redux'
import Loading from './Loading'
import UIRoot from './UIRoot'
import * as errorReporter from '@src/lib/errorReporter'

type UIRootContainerProps = {
  data?: {
    loading: boolean,
    current_user?: {
      id: string,
      email: string,
      first_name: string,
      last_name: string,
      tawk_validation_hash: string,
      region: {
        id: string,
        slug: string,
        name: string,
        time_zone: string,
      },
      companies: Array<{
        id: string,
        name: string,
      }>,
      offices: Array<{
        id: string,
        name: string,
        company: {
          id: string,
        },
        region: {
          id: string,
          slug: string,
          name: string,
          time_zone: string,
          currency: string,
        },
      }>,
    },
  },
  userIsLoggedIn: boolean,
  userPreferences: {
    currentCompanyId?: string,
    currentOfficeId?: string,
  },
  setCurrentCompanyId: (companyId: string) => void,
  setCurrentOfficeId: (officeId: string) => void,
  setCurrentRegionId: (regionId: string) => void,
  setCurrentRegion: (region: Object) => void,
}

type UIRootContainerState = {
  configuringI18n: boolean,
  language?: string,
  messages?: { [id: string]: string },
}

export class UIRootContainer extends Component<
  UIRootContainerProps,
  UIRootContainerState
> {
  componentDidUpdate(prevProps: UIRootContainerProps) {
    const previouslyLoading = prevProps.data && prevProps.data.loading
    const notLoadingNow = this.props.data && !this.props.data.loading
    const {
      data,
      setCurrentRegion,
      setCurrentCompanyId,
      setCurrentRegionId,
      setCurrentOfficeId,
    } = this.props
    let { currentCompanyId, currentOfficeId } = this.props.userPreferences

    if (previouslyLoading && notLoadingNow) {
      this.updateSupportChatUserInfo()
      this.updateErrorReporterWithUserInfo()
    }

    if (!currentCompanyId) {
      const company =
        data && data.current_user && data.current_user.companies[0]

      if (company && company.id) {
        setCurrentCompanyId(company.id)
      }
    }

    if (currentOfficeId) {
      const office =
        data &&
        data.current_user &&
        data.current_user.offices.find(office => office.id === currentOfficeId)

      if (office && office.id) {
        setCurrentRegion(office.region)
      }
    } else {
      const office =
        data &&
        data.current_user &&
        data.current_user.offices.find(
          office => office.company.id === currentCompanyId
        )

      if (office && office.id) {
        setCurrentRegionId(office.region.id)
        setCurrentRegion(office.region)
        setCurrentOfficeId(office.id)
      }
    }
  }

  updateSupportChatUserInfo() {
    if (!this.props.data || !this.props.data.current_user) {
      return
    }

    const {
      email,
      first_name: firstName,
      last_name: lastName,
      tawk_validation_hash: tawkValidationHash,
    } = this.props.data.current_user

    const waitForChat = window.setInterval(() => {
      if (window.Tawk_API === undefined) {
        return
      }

      window.Tawk_API.visitor = {
        name: `${firstName} ${lastName}`,
        email: email,
        hash: tawkValidationHash,
      }

      clearInterval(waitForChat)
    }, 100)
  }

  updateErrorReporterWithUserInfo() {
    if (this.props.data && this.props.data.current_user) {
      errorReporter.setUser(this.props.data.current_user.email)
    }
  }

  getLocaleFromRegionId(regionId?: string) {
    switch (regionId) {
      case '1':
      case '2':
        return 'es-MX'
      case '3':
        return 'es-PE'
      default:
        return 'es-MX'
    }
  }

  isLoading() {
    const tasks = [this.props.data && this.props.data.loading]

    const loading = task => task === true
    return tasks.some(loading)
  }

  render() {
    if (this.isLoading()) {
      return <Loading />
    }

    const region =
      this.props.data &&
      this.props.data.current_user &&
      this.props.data.current_user.region
    const locale = this.getLocaleFromRegionId(region && region.id)
    const timeZone = (region && region.time_zone) || 'America/Mexico_City'

    const data = mapKeys(this.props.data, (v, k) => camelCase(k))

    return (
      <IntlProvider locale={locale} timeZone={timeZone}>
        <UIRoot initialData={data} />
      </IntlProvider>
    )
  }
}

export default compose(
  withRouter,
  redux,
  graphql
)(UIRootContainer)
