// @flow
import UnexpectedErrorModal from '@src/components/modals/UnexpectedErrorModal'

import { MAXIMUM_DIFFERENCE_TO_PAY_ORDER } from '@src/constants/balance'

type Company = {
  id: string,
  credit_period: number,
}

export type Office = {
  id: string,
  balance: number,
}

export function companyHasCredit(company?: Company): boolean {
  return !!company && company.credit_period > 0
}

export function officeHasBudget(office?: {
  monthly_budget?: ?number,
}): boolean {
  return (
    !!office &&
    office.monthly_budget !== undefined &&
    parseInt(office.monthly_budget) > 0
  )
}

export function officeHasEnoughBalance(
  orderPrice: number,
  office: Office
): boolean {
  var balanceAndPriceDifference = office.balance - orderPrice

  return !!office && balanceAndPriceDifference > MAXIMUM_DIFFERENCE_TO_PAY_ORDER
}

export function handleUnexpectedError(e: *, openModal: Function) {
  const error = (e.graphQLErrors && e.graphQLErrors[0].message) || e.message

  openModal(UnexpectedErrorModal(error))
}
