// @flow
import React from 'react'

import redux from './redux'
import ModalWrapper from './ModalWrapper'
import AutopilotWelcomeModal from './AutopilotWelcomeModal'
import OfficeInformationModal from './OfficeInformationModal'
import BankTransferModal from './BankTransferModal'
import NotEnoughBalanceModal from './NotEnoughBalanceModal'
import ChargeOrderModal from './ChargeOrderModal'
import NewMasterListModal from './NewMasterListModal'
import UpdateMasterListModal from './UpdateMasterListModal'
import ConfirmActionModal from './ConfirmActionModal'
import CreateCreditCardModal from './CreateCreditCardModal'
import NewOfficeModal from './NewOfficeModal'
import InviteUserToOfficeModal from './InviteUserToOfficeModal'
import RequestProductModal from './RequestProductModal'
import ImageTitleBodyButtonModal from './ImageTitleBodyButtonModal'
import MasterListRequestedModal from './MasterListRequestedModal'

function getModalElement(
  type?: string,
  wrapperProps: *,
  modalProps: Object = {}
) {
  switch (type) {
    case 'OfficeInformationModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <OfficeInformationModal {...modalProps} />
        </ModalWrapper>
      )

    case 'AutopilotWelcomeModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <AutopilotWelcomeModal {...modalProps} />
        </ModalWrapper>
      )

    case 'BankTransferModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <BankTransferModal {...modalProps} />
        </ModalWrapper>
      )

    case 'ChargeOrderModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <ChargeOrderModal {...modalProps} />
        </ModalWrapper>
      )

    case 'NotEnoughBalanceModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <NotEnoughBalanceModal {...modalProps} />
        </ModalWrapper>
      )

    case 'NewMasterListModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <NewMasterListModal {...modalProps} />
        </ModalWrapper>
      )

    case 'UpdateMasterListModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <UpdateMasterListModal {...modalProps} />
        </ModalWrapper>
      )

    case 'ConfirmActionModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <ConfirmActionModal {...modalProps} />
        </ModalWrapper>
      )

    case 'CreateCreditCardModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <CreateCreditCardModal {...modalProps} />
        </ModalWrapper>
      )

    case 'NewOfficeModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <NewOfficeModal {...modalProps} />
        </ModalWrapper>
      )

    case 'InviteUserToOfficeModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <InviteUserToOfficeModal {...modalProps} />
        </ModalWrapper>
      )

    case 'RequestProductModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <RequestProductModal {...modalProps} />
        </ModalWrapper>
      )

    case 'ImageTitleBodyButtonModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <ImageTitleBodyButtonModal {...modalProps} />
        </ModalWrapper>
      )

    case 'MasterListRequestedModal':
      return (
        <ModalWrapper {...wrapperProps}>
          <MasterListRequestedModal {...modalProps} />
        </ModalWrapper>
      )

    default:
      return null
  }
}

type ModalRootProps = {
  data: ?{
    type: string,
    closeable?: boolean,
    onCancel?: () => void,
  },
  close: () => void,
}

export function ModalRoot(props: ModalRootProps) {
  const { type, closeable, onCancel, ...modalProps } = props.data || {}

  const modalWrapperProps = {
    closeable,
    onCancel,
    closeModal: props.close,
  }

  const finalModalProps = {
    closeModal: props.close,
    ...modalProps,
  }

  return getModalElement(type, modalWrapperProps, finalModalProps)
}

export default redux(ModalRoot)
