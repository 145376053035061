// @flow
import React from 'react'
import cx from 'classnames'
import { FormattedMessage, defineMessages } from 'react-intl'

import styles from './NewMasterListModal.module.scss'
import Button from '@src/components/ui/Button'
import Input from '@src/components/ui/Input'
import FormValidationError from '@src/components/ui/FormValidationError'
import { isRequired } from '@src/utils/validation'

import type { MessageDescriptor } from 'react-intl'

const messages = defineMessages({
  nameLabel: {
    id: 'NewMasterListModal.nameLabel',
    defaultMessage: 'Nombre de la lista',
    description: 'Label for the form field asking the name of the new list',
  },
})

type NewMasterListModalProps = {
  handleSubmit: () => void,
  submitting: boolean,
  error?: any,
  intl: {
    formatMessage: (
      messageDescriptor: MessageDescriptor,
      values?: Object
    ) => string,
  },
}

export default function NewMasterListModal(props: NewMasterListModalProps) {
  const {
    handleSubmit,
    submitting,
    error,
    intl: { formatMessage },
  } = props

  return (
    <div className={cx(styles.container)}>
      <div className={styles.header}>
        <h1 className="text-title text-center">
          <FormattedMessage
            id="NewMasterListModal.title"
            defaultMessage="Guardar lista nueva"
            description="New master list modal title"
          />
        </h1>
      </div>

      <form className={styles.body}>
        <p className="text-small-body text-dark">
          <FormattedMessage
            id="NewMasterListModal.description"
            defaultMessage="Crear una lista predeterminada con los productos y cantidades  seleccionadas para los futuros pedidos."
            description="New master list description"
          />
        </p>

        <Input
          name="name"
          label={formatMessage(messages.nameLabel)}
          validate={[isRequired]}
        />

        {error && <FormValidationError error={error} />}

        <div className={cx(styles.section, styles.actionButtons)}>
          <Button
            cta
            className={styles.confirmButton}
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <FormattedMessage
                id="NewMasterListModal.submitButtonWhileSubmitting"
                defaultMessage="Creando la lista..."
                description="Submit button text while creating a new list"
              />
            ) : (
              <FormattedMessage
                id="NewMasterListModal.submitButton"
                defaultMessage="Crear lista"
                description="Submit button text to create a new list"
              />
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}
