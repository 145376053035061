// @flow
/* eslint-disable react/style-prop-object */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import gql from 'graphql-tag'

import Logo from '@src/components/ui/Logo'
import styles from './Header.module.scss'
import * as errorReporter from '@src/lib/errorReporter'
import OutsideClickHandler from '@src/components/ui/OutsideClickHandler'
import HeaderDropdownMenu from '@src/components/ui/HeaderDropdownMenu'
import { logOut } from '@src/utils/authorization'

import { officeHasBudget } from '@src/lib/payOrder'

type Office = {
  id: string,
  name: string,
  balance: number,
  monthly_budget?: ?number,
  region: {
    id: string,
  },
}

type HeaderProps = {
  isUserLoggedIn: boolean,
  currentOfficeId: string,
  setCurrentOfficeId: (officeId: string) => void,
  setCurrentRegionId: (regionId: string) => void,
  currency: string,
  initialData: {
    loading: boolean,
    currentUser: {
      id: string,
      first_name: string,
      last_name: string,
      email: string,
      offices: Array<Office>,
    },
  },
}

export default class Header extends Component<HeaderProps, *> {
  static fragments: {
    office: string,
  }
  onClickOffice: Function

  constructor() {
    super()

    this.state = {
      isDropdownMenuOpen: false,
    }

    this.onClickOffice = this.onClickOffice.bind(this)
  }

  logout() {
    errorReporter.resetUser()
    logOut()

    window.Tawk_API.endChat()
    window.location.replace('/')
  }

  onClickOffice(office: { id: string, region: { id: string } }) {
    if (office.id !== this.props.currentOfficeId) {
      this.props.setCurrentOfficeId(office.id)
      this.props.setCurrentRegionId(office.region.id)
      window.location.replace(this.nextPath(office.id))
    }
  }

  nextPath(officeId: string) {
    const currentPath = window.location.href
    const resourceRegex = /(?:\/)(\d+)/
    const officeDetailPath = /(?:\/)offices\/(\d+)/

    // We want to strip the ids from the path because we could potentially
    // be accessing a resource of one office from another. Like master lists
    // and end up confusing the user about which office was bound to the new
    // order
    if (officeDetailPath.test(currentPath)) {
      return '/offices/' + officeId
    } else if (resourceRegex.test(currentPath)) {
      return currentPath.replace(resourceRegex, '')
    } else {
      return currentPath
    }
  }

  renderLoggedIn() {
    const { currentOfficeId } = this.props
    const { currentUser, loading } = this.props.initialData

    if (!currentUser || loading) {
      return null
    }

    const currentOffice =
      currentUser.offices.find(office => office.id === currentOfficeId) || {}
    const hasBudget = officeHasBudget(currentOffice)

    return (
      <div className={styles.loggedIn}>
        {hasBudget && (
          <div className="d-flex align-items-center pr-4 pl-4">
            <span className={cx('text-body text-dark', styles.balance)}>
              <FormattedMessage
                id="Header.balance"
                defaultMessage="Presupuesto restante: {amount}"
                description="Office balance"
                values={{
                  amount: (
                    <span
                      className={cx(
                        'text-body-2',
                        currentOffice.balance < 0 && styles.negativeBalance,
                        currentOffice.balance > 0 && styles.positiveBalance
                      )}
                    >
                      <FormattedNumber
                        value={currentOffice.balance / 100 || 0}
                        currency={this.props.currency}
                        style="currency"
                        currencyDisplay="symbol"
                      />
                    </span>
                  ),
                }}
              />
            </span>
          </div>
        )}

        <OutsideClickHandler
          className={cx('pl-4', styles.userMenu)}
          onOutsideClick={() =>
            this.setState(() => ({ isDropdownMenuOpen: false }))
          }
        >
          <div
            className={styles.userButton}
            onClick={() =>
              this.setState(state => ({
                isDropdownMenuOpen: !state.isDropdownMenuOpen,
              }))
            }
          >
            <span className={cx('text-dark', styles.name)}>
              {(currentOffice && currentOffice.name) ||
                `${currentUser.first_name} ${currentUser.last_name}`}
            </span>

            <span className={styles.caret} />
          </div>

          <HeaderDropdownMenu
            className={styles.dropdownMenu}
            isOpen={this.state.isDropdownMenuOpen}
            onLogout={() => this.logout()}
            onClickOffice={office => this.onClickOffice(office)}
            current_user={currentUser}
            currentOfficeId={currentOfficeId}
          />
        </OutsideClickHandler>
      </div>
    )
  }

  renderLoggedOut() {
    return (
      <div className={styles.loggedOut}>
        <ul>
          <li>
            <Link to="/login">
              <FormattedMessage
                id="Header.logIn"
                defaultMessage="Inicia Sesión"
                description={`Link to log in page`}
              />
            </Link>
          </li>
        </ul>
      </div>
    )
  }

  render() {
    const { isUserLoggedIn } = this.props

    return (
      <div className={styles.container}>
        <Link to="/">
          <Logo />
        </Link>

        <div className={styles.rightNav}>
          {isUserLoggedIn ? this.renderLoggedIn() : this.renderLoggedOut()}
        </div>
      </div>
    )
  }
}

Header.fragments = {
  office: gql`
    fragment HeaderOffice on Office {
      id
      balance
      monthly_budget
      region {
        id
      }
    }
  `,
}
